@use "src/styles/color";
@use "src/styles/spacing";
@use "src/styles/media";

.followBlock {
  display: flex;
  align-items: center;
  background: color.$grayscale100;
  justify-content: center;
  width: 100%;

  border-radius: spacing.$XXXXS;
  padding: 8px;

  .counter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
    width: 100%;

    cursor: pointer;

    &_columnMode {
      flex-direction: column;
      gap: 0;
    }
  }
}
