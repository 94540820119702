@use "src/styles/color";
@use "src/styles/media";

.header {
  flex: 0 0 0;
  width: 100%;
  box-sizing: border-box;
  top: 0;
  display: flex;
  min-height: 80px; // Equal to Tabs height
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid color.$grayscale200;
  padding: 10px 20px;
  background: color.$white;
}

.left_side {
  display: flex;
  width: 100%;
  overflow: hidden;
  align-items: center;
}

.avatarAndTitle {
  display: flex;
  width: fit-content;
  overflow: hidden;
  align-items: center;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  margin-left: 8px;
  width: 100%;
}

.interactive {
  cursor: pointer;
}
