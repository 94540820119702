@use "src/styles/color";
@use "src/styles/media";

.wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  background: color.$white;
  overflow: scroll;
  height: 100%;
  scrollbar-width: none;

  @include media.tp {
    border-radius: 26px;
  }

  @include media.d {
    height: 100%;
  }

  &.profileView {
    overflow: hidden;
  }
}

.wrap::-webkit-scrollbar {
  display: none;
}
