@use "src/styles/color";

.wrap {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.bigMessage {
  border-radius: 12px;
  background-color: color.$white;
  width: 100%;
  height: 67px;
}

.jobInfo {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .position {
    border-radius: 12px;
    background-color: color.$white;
    width: 100%;
    height: 19px;
  }

  .company {
    border-radius: 12px;
    background-color: color.$white;
    height: 15px;
  }
}

.icons {
  display: flex;
  gap: 8px;

  .icon {
    background-color: color.$white;
    width: 16px;
    height: 16px;
    border-radius: 8px;
  }
}

.signature {
  border-radius: 12px;
  background-color: color.$white;
  width: 182px;
  height: 15px;
}
