@use "src/styles/media";
@use "src/styles/color";

.container {
  width: 100%;
}

.header {
  width: 100%;
  padding: 18px 0 19px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: color.$grayscale100;
  @include media.tp {
    padding: 22px 0px 24px 0px;
  }
}
.iconButton {
  display: flex;
  cursor: pointer;
}
.buttonsSection {
  display: flex;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  cursor: pointer;
  justify-content: flex-end;
  gap: 36px;
}

.button {
  display: none;
}
.avatar {
  display: flex;
  width: 100%;
  align-items: center;
  flex-grow: 1;
  cursor: pointer;
  justify-content: flex-end;
  gap: 6px;
}
.avatarCp {
  gap: 6px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.nav {
  display: none;
}
.menu {
  display: none;
}
.exitEvent {
  display: none;
}

.buttons {
  flex-grow: 1;
  justify-content: flex-end;
  display: flex;
}
.logo svg {
  height: 27px;
  max-width: 93.5px;
}
.desktopOnly .logo svg {
  max-width: 118px;
  flex-grow: 1;
  height: 34px;
}
.button {
  display: none;
}
.exitIcon {
  display: none;
}
.navTablet {
  display: none;
}
.menuTablet {
  display: flex;
}
.iconButtonTablet {
  display: flex;
  gap: 10px;
  align-items: center;
}
.buttonTablet {
  display: none;
}

@media screen and (min-width: 768px) {
  .iconButton {
    align-items: center;
    gap: 10px;
  }
  .exitIcon {
    display: block;
    cursor: pointer;
  }
  .logo svg {
    max-width: 118px;
    flex-grow: 1;
    height: 34px;
  }
  .menu {
    display: flex;
  }
}
@media screen and (min-width: 1024px) {
  .nav {
    display: flex;
    flex-grow: 2;
    gap: 48px;
    justify-content: center;
    align-items: center;
  }

  .exitIcon {
    display: none;
  }
  .button {
    display: flex;
  }
  .exitEvent {
    display: block;
  }
  .iconButton {
    display: none;
  }
  .logo {
    max-width: unset;
  }
  .buttons {
    width: 100%;
  }
  .menu {
    display: none;
  }
}
