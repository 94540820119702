@use "src/styles/color";

.placeholder {
  background-color: color.$grayscale100;
  width: 100%;
  height: 100%;
  border-radius: 10px;

  &.isRounded {
    border-radius: 100%;
  }
}
