@use 'src/styles/color';
@use 'src/styles/media';

.deletion {
  display: flex;
  flex-direction: column;
  gap: 8px;
  @include media.tp {
    gap: 12px;
  }
  @include media.d {
    gap: 16px;
  }
}

.input {
  margin: 16px 0;
  width: 100%;
  input {
    width: 100%;
    min-width: unset;
  }
  @include media.tp {
    margin: 24px 0;
  }
  @include media.d {
    margin: 16px 0;
  }
  @include media.dhd {
    margin: 20px 0;
  }
}
