@use "src/styles/color";
@use "src/styles/media";
@use "src/styles/spacing";

.wrap_honeycomb {
  background-color: white;
  border-radius: 16px;
  width: 100%;
  box-sizing: border-box;
  @include media.d {
    border-radius: 24px;
  }
}
.desktopOnly.wrap_honeycomb {
  border-radius: 24px;
}

.wrap_honeycomb_a {
  padding: spacing.$S spacing.$XXXS;
  @include media.tp {
    padding: spacing.$L spacing.$XXXL;
  }
  @include media.d {
    padding: spacing.$M spacing.$XL;
  }
  @include media.ds {
    padding: spacing.$L spacing.$L;
  }
  @include media.dhd {
    padding: spacing.$L spacing.$XXXXL;
  }
}
.desktopOnly.wrap_honeycomb_a {
  padding: spacing.$M spacing.$XL;
  @include media.ds {
    padding: spacing.$L spacing.$L;
  }
  @include media.dhd {
    padding: spacing.$L spacing.$XXXXL;
  }
}


@mixin hcBPadding {
  padding: spacing.$XXXS;
  @include media.d {
    padding: spacing.$XXS;
  }
  @include media.dhd {
    padding: spacing.$XS;
  }
}
.wrap_honeycomb_b {
  @include hcBPadding;
}
.paddingAsMargin {
  padding: 0;
  margin: spacing.$XXXS;
  @include media.d {
    margin: spacing.$XXS;
  }
  @include media.dhd {
    margin: spacing.$XS;
  }
}
.movePaddingsToChildren.wrap_honeycomb_b {
  padding: 0;
}
.movePaddingsToChildren.wrap_honeycomb_b > div {
  @include hcBPadding;
}


@mixin hcBPadding_desktopOnly {
  padding: spacing.$XXS;
  @include media.dhd {
    padding: spacing.$XS;
  }
}
.desktopOnly.wrap_honeycomb_b {
  @include hcBPadding_desktopOnly;
}
.desktopOnly.movePaddingsToChildren.wrap_honeycomb_b {
  padding: 0;
}
.desktopOnly.movePaddingsToChildren.wrap_honeycomb_b > div {
  @include hcBPadding_desktopOnly;
}


.squareTopCorners.squareTopCorners {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.squareBottomCorners.squareBottomCorners {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.bottomUnderline.bottomUnderline {
  border-bottom: 1px solid color.$grayscale200;
}
