@use "src/styles/color";
@use "src/styles/media";
@use "src/styles/spacing";

.chatList {
  overscroll-behavior: contain;

  position: relative;
  width: 100%;
  overflow-y: auto;
  box-sizing: content-box;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  padding: spacing.$XXXXS 0;
}

.chatList::-webkit-scrollbar {
  width: 24px;
}

.chatList::-webkit-scrollbar-thumb {
  border: 8px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 12px;
  background-color: color.$grayscale200;
}