@use 'src/styles/media';
@use 'src/styles/common.module';

.modal {
  position: relative;
  box-sizing: border-box;
  z-index: 21;
  background: #ffffff;
  border-radius: 20px;
  width: 100%;
  padding: 24px;
  @include media.tp {
    padding: 64px;
    width: 376px;
  }
}
.modal.defaultPosition {
  left: 50%;
  transform: translateX(-50%);
}
.desktopOnly.modal {
  padding: 64px;
  width: 376px;
}

.cross_icon {
  @extend .noSelect;
  cursor: pointer;
  position: absolute;
  right: 24px;
  top: 24px;
  margin-left: auto;
}

.titleStyle {
  margin-bottom: 8px;
  @include media.d {
    margin-bottom: 20px;
  }
}
.desktopOnly .titleStyle {
  margin-bottom: 20px;
}

.buttonsSection {
  margin-top: 24px;

  button {
    width: 100%;
    @include media.tp{
      height: 56px;
      border-radius: 12px;
    }
  }
  display: flex;
  flex-direction: column;
  gap: 8px;
  @include media.tp {
    flex-direction: row-reverse;
  }
}
.desktopOnly .buttonsSection {
  button {
      height: 56px;
      border-radius: 12px;
  }
  flex-direction: row-reverse;
}
