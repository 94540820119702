@use "src/styles/color";
@use "src/styles/media";

.separator {
  text-align: center;
  padding: 12px;
  padding-top: 12px;
  background-color: #ffffffbd;

  position: sticky;
  top: -16px;
  z-index: 1;
}

.text {
  white-space: nowrap;
  display: flex;
  align-items: center;
  &::before,
  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: color.$grayscale100;
  }
  &::before {
    margin-right: 33px;
  }
  &::after {
    margin-left: 33px;
  }
}
