@use 'src/styles/color';
@use 'src/styles/media';
@use "../../common";

.wrap {
  @include common.wrapper;
}

.wrap_inner {
  @include common.wrapper-inner;
}

.wrap input{
  width: 100%;
  min-width: unset;
}

.title{
  display: none;
  @include media.d {
    margin-bottom: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
.hr_top{
  display: none;
  @include media.d{
    display: inline-block;
    border: 0;
    margin: 0;
    border-top: 1px solid color.$grayscale100;
    width: 100%;
  }
}
.submit {
  display: flex;
  justify-content: flex-end;
  @include media.d{
    display: none;
  }
}
.submit button{
  width: 100%;
  @include media.tp{
    width: unset;
  }
}
.label{
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  @include media.d{
    flex-grow: 2;
    flex-basis: 20px;
  }
}
.input{
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  @include media.d{
    flex-direction: row;
    gap: 64px;
  }
  @include media.ds{
    flex-direction: row;
    gap: 99px;
  }
}

.form hr {
  margin-top: 24px;
  margin-bottom: 24px;
  display: inline-block;
  border: 0;
  border-top: 1px solid color.$grayscale100;
  width: 100%;
}
.fullwidth{
  flex-grow: 3;
}

.submit_button {
  width: 100%;
  margin-top: 16px;
  @include media.tp {
    width: 154px;
    margin-top: 24px;
  }
}