@use "src/styles/color";
@use "src/styles/media";
@use "src/styles/spacing";
@use "src/styles/common.module";

.wrap {
  background-color: color.$grayscale100;
  border-radius: 26px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 24px 18px;
  padding: 28px 24px;

  .closeButton {
    @extend .noSelect;
    cursor: pointer;
    width: fit-content;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: spacing.$XXXS;
  }

  .followCounterBlock.followCounterBlock {
    background-color: color.$white;
  }

  .interactionWithAnotherUserBlock {
    display: flex;
    flex-direction: column;
    gap: spacing.$XXXXS;
  }

  .companyAndSocialMediaBlock {
    display: flex;
    flex-flow: column;
    gap: spacing.$XXXS;
  }
}
