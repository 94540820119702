@use "src/styles/media";

.info {
  display: flex;
  align-items: center;
  gap: 4px;

  margin-bottom: 16px;
}

.caption.caption {
  margin-bottom: 12px;
}

.titleInscription.titleInscription {
  margin-bottom: 4px;
}

.title.title {
  margin-bottom: 12px;
}

.spinner.spinner {
  margin: auto;
  margin-bottom: 12px;
}


.modalButtons {
  width: 100%;
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;

  @include media.tp {
    flex-direction: row;
  }
}

.declineInvitationTemplate.declineInvitationTemplate {
  @include media.tp {
    width: 559px;
  }
}
