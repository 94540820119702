@use "src/styles/media";

$grid-gap: 8px;

@mixin wrap($columns-count: (mp: 4, tp: 6, tl: 12)) {
  display: grid;
  grid-template-columns: repeat(map-get($columns-count, mp), 1fr);
  grid-column-gap: $grid-gap;

  @include media.tp() {
    grid-template-columns: repeat(map-get($columns-count, tp), 1fr);
  }
  @include media.tl() {
    grid-template-columns: repeat(map-get($columns-count, tl), 1fr);
  }
}
@mixin wrap_desktopOnly {
  display: grid;
  grid-column-gap: $grid-gap;
  grid-template-columns: repeat(12, 1fr);
}

@mixin All() {
  grid-area: auto / 1 / auto / 5;

  @include media.tp() {
    grid-area: auto / 1 / auto / 7;
  }
  @include media.tl() {
    grid-area: auto / 1 / auto / 13;
  }
}
@mixin desktopOnly() {
  grid-area: auto / 1 / auto / 13;
}
