@use "src/styles/color";
@use "src/styles/spacing";

$system-message--max-width: 342px;

$list-item--padding--dhd: spacing.$XS;
$list-item--padding--d:   20px;
$list-item--padding--tp:  28px;
$list-item--padding--m:   20px;

$message-list--padding-x--md: spacing.$XXXS;
$message--padding: spacing.$XXXS;
$message--border-radius: 16px;
$message--inner-gap: spacing.$XXXS;


$list-item-wrapper--padding-x: spacing.$XXXXS;

$list-item--background--normal: color.$white;
$list-item--background--hover:  color.$grayscale100;
$list-item--background--active: color.$grayscale300;

$list-separator--color: color.$grayscale100;
$list-separator--width: 1px;


$unread-messages--size: 20px;
$unread-messages--gap: 4px;


$avatar--size: 32px;
$corner--width: 12px;
$corner--offset: 1px;  // Должен заходить на 1 пиксель на сообщение чтобы избежать "зазора" между
$corner--height: 16px;