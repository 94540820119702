@use "src/styles/color";
@use "src/styles/spacing";
@use "src/styles/text";
@use "../../../../../../variables";

.wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: variables.$message--border-radius;
  width: fit-content;
  max-width: variables.$system-message--max-width;
  background: color.$yellow100;
}

.positionLeft {
  width: fit-content;
  margin-right: auto;
  margin-left: variables.$corner--width;

  & + & {
    margin-top: spacing.$XXXXS;
  }
}

.positionRight {
  width: fit-content;
  margin-left: auto;
  margin-right: variables.$corner--width;
}

.content {
  padding: variables.$message--padding;
  border-top: 1px solid color.$tangerine200;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: variables.$message--inner-gap;
}

.event {
  font-weight: 600 !important; // [LINK_COMPONENT_STYLES]
  display: inline !important; // [LINK_COMPONENT_STYLES]
}

.title {
  display: flex;
  gap: spacing.$XXXXS;
  padding: variables.$message--padding;
}

.titleIcon {
  position: relative;
  top: .23em;
}

.user {
  display: flex;
  align-items: center;
  gap: spacing.$XXXXS;

  cursor: pointer;

  &Role {
    font-size: 10px !important; // [UI-Kit Style Priority]
    line-height: 15px !important; // [UI-Kit Style Priority]
  }

  .userFullName {
    //width: 100%;
    //overflow: hidden;
    //white-space: nowrap;
    @include text.ellipsisAtRow(1);
  }
}

.newDateTime {
  display: flex;
  flex-direction: column;
  gap: spacing.$XXXXS;
}
