.name_message {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
}

.clickable {
  cursor: pointer;
  pointer-events: initial;
}

.nonClickable {
  pointer-events: none;
}
