@use 'src/styles/color';
@use 'src/styles/media';
@use 'src/styles/text';
@use "../../common";

.wrap {
  @include common.wrapper;
}

.wrap_inner {
  @include common.wrapper-inner;
}

.title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @include media.d {
    margin-bottom: 24px;
  }
}

.hr_top {
  display: none;
  @include media.d {
    display: inline-block;
    border: none;
    margin: 0;
    border-top: 1px solid color.$grayscale100;
    width: 100%;
  }
}

.content {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  @include media.tp {
    gap: 40px;
  }
  @include media.d {
    flex-direction: row;
    align-items: unset;
  }
  @include media.dhd {
    gap: 134px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.title {
  display: none;
  @include media.d {
    display: block;
  }
}

.section {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;
  @include media.tp {
    gap: 16px;
  }
  @include media.d{
    gap: 12px;
  }
}

.email {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  justify-content: space-between;

  button {
    padding: 0;
    height: unset;
  }

  @include media.d {
    justify-content: flex-start;
    gap: 20px;
    align-items: center;
  }
}

.hr_bottom {
  border: none;
  margin: 24px 0;
  border-top: 1px solid color.$grayscale100;
  width: 100%;
  @include media.d {
    margin: 28px 0;
  }
}

.fullwidth button {
  width: 100%;
  @include media.tp {
    width: unset;
  }
}

.changePassword {
  display: flex;
  flex-direction: column;
  gap: 8px;
  @include media.tp {
    flex-direction: row;
  }
}

.form_width {
  width: 100%;
}
.section_label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  button {
    padding: {
      top: 0;
      bottom: 0;
    }
  }
}

.email_text {
  @include text.longWordBreak;
}