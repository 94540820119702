@use "src/styles/color";
@use "src/styles/media";

.wrap {
  position: fixed;
  width: 100vw;
  height: 100%;
  overflow: hidden;
  top: 0;
  bottom: 8px;
  z-index: 20;
  @include media.tp{
    transform: translate(-50%, -50%);
    bottom: unset;
    left: 50%;
    top: 50%;
  }
}
.desktopOnly.wrap {
  //width: media.$Desktop;
  overflow: scroll;
  bottom: 0;

  @include media.d{
    transform: translate(-50%, -50%);
    bottom: unset;
    left: 50%;
    top: 50%;
  }
}

.background {
  position: absolute;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  background: RGBA(15, 15, 15, 0.6);
}
.desktopOnly .background {
  width: media.$Desktop;
  @include media.d {
    width: 100vw;
  }
}

.children_wrap {
  position: absolute;
  width: 100%;
  padding: 0 8px;
  bottom: 8px;
  left: 0;
  z-index: 21;
  @include media.tp{
    width: unset;
    transform: translate(-50%, -50%);
    bottom: unset;
    left: 50%;
    top: 50%;
  }
}
.desktopOnly .children_wrap {
  width: media.$Desktop;

  left: media.$Desktop / 2;
  top: 50%;
  transform: translate(-50%, -50%);
  bottom: unset;

  @include media.d{
    width: 100%;
    transform: translate(-50%, -50%);
    bottom: unset;
    left: 50%;
    top: 50%;
  }
}
