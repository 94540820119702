@use "src/styles/spacing";
@use "src/styles/text";
@use "src/styles/color";

$youAreLeavingEvvveModalMinWidth: 467px;

.socialMediaIcons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  width: 100%;

  .socialMediaIcon {
    cursor: pointer;
  }
}

.youAreLeavingEvvveModal {
  min-width: $youAreLeavingEvvveModalMinWidth;
  &_mobile {
    min-width: unset;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: spacing.$XS;
    word-break: break-all;

    .test {
      @include text.ellipsisAtRow(6);
    }
  }
}