.readonly {
  cursor: auto;
  pointer-events: none;
}

// Avoid onTouch visual effect on touchscreen devices
.noTouchCallout {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

// Prevent user text selection
.noSelect {
  user-select: none;
}

// Avoid onTouch visual effect on touchscreen devices
.noTouchCallout {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

// [UI-Kit fix button]:
// если текст не влезает в кнопку, то выходит за ее пределы
// если кнопка не влезает в контейнер, она сжимается, текст выходит за ее пределы в несколько строк
// нужно запретить перенос текста на 2ую строку и сжатие кнопки
// Ex: "Event info"
.button {
  white-space: nowrap;
}
