@use "src/styles/color";
@use "src/styles/media";
@use "../../common";

.wrap {
  @include common.wrapper;
}

.wrap_inner {
  @include common.wrapper-inner;
}

.wrap input {
  min-width: unset;
}

.title {
  display: none;
  @include media.d {
    margin-bottom: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.wrap hr {
  display: none;
  @include media.d {
    display: inline-block;
    border: 0;
    margin: 0;
    border-top: 1px solid color.$grayscale100;
    width: 100%;
  }
}

.content {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  @include media.tp {
    gap: 40px;
  }
  @include media.d {
    gap: 48px;
    flex-direction: row;
    align-items: unset;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

.form_width {
  width: 100%;
}

.submit_button {
  @include media.tp {
    width: 154px;
  }
}

.avatar.avatar {
  @include media.ds {
    width: 178px;
    height: 178px;
  }
}
