@use "src/styles/media";

.leaveGroupModalButtons {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;

  @include media.tp {
    flex-direction: row;
  }
}
