@use "src/styles/color";

.warning {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  background: color.$yellow100;
  padding: 4px 6px;
  border-radius: 12px;
  height: 20px;
  overflow: hidden;
  max-width: 100%;

  > p {
    margin-left: 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.wrapper {
  max-width: 100%;
  overflow: hidden;
}

.message_style {
  cursor: pointer;
  overflow: hidden;
  width: 200px;
}

.text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}
