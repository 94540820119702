@use "../../../styles/media";
@use "../../../styles/spacing";


.modal {
  display: flex;
  flex-flow: column;
  gap: spacing.$XS;

  .text {
    display: flex;
    flex-flow: column;
    gap: spacing.$XXXXS;
  }

  .button {
    width: 100%;
  }

  @include media.tp {
    flex-flow: row;
    justify-content: space-between;
    gap: 44px;
    align-items: center;
    padding: 24px;

    .button {
      width: fit-content;
    }
  }
}
