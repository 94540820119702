@use 'src/styles/media';

.title {
  margin-bottom: 8px;
  @include media.tp {
    margin-bottom: 20px;
  }
}

.attention {
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  @include media.tp {
    gap: 16px;
  }
}
.buttons button{
  width: 100%;
  height: 48px;
  @include media.tp {
    height: 56px;
  }
}
.buttons {
  display: flex;
  flex-direction: column;
  gap: 8px;
  @include media.tp{
    flex-direction: row-reverse;
  }
}