@use "src/styles/color";

.wrap {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: 20;
}

.background {
  position: absolute;
  width: 100vw;
  z-index: -1;
  height: 100%;
  top: 0;
  left: 0;
  background: RGBA(15, 15, 15, 0.6);
}