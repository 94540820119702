@use "src/styles/color";
@use "src/styles/media";
@use "src/styles/spacing";

.header {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  gap: 36px;
  padding: 25px 20px 26px 20px;
  border-bottom: 1px solid color.$grayscale200;

  @include media.tp {
    gap: 18px;
  }

  @include media.d {
    gap: 35px;
  }

  @include media.dhd {
    gap: 18px;
  }
}

.backButtonAndTitle {
  display: flex;
  gap: 12px;
}

.backButton {
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
}

.search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: spacing.$XXXS spacing.$XS;
}

.search_input {
  width: 100%;

  > div {
    width: 100%;
  }

  div input {
    width: 100%;
    background: color.$grayscale100;
    border-radius: 12px;
    height: 52px;
    border: 1px solid transparent !important;
    caret-color: color.$grayscale400;
    box-shadow: none;
    padding: 12px 14px !important; // Redefines lib styles

    &:active {
      border: 1px solid transparent !important;
      box-shadow: none;
    }
    &:hover {
      border: 1px solid transparent !important;
      box-shadow: none;
    }

    &::placeholder {
      color: color.$grayscale400;
    }
  }

  label {
    background: color.$grayscale100;
    height: 52px;
    box-shadow: none;
    border: 1px solid transparent;

    &:active {
      border: 1px solid transparent !important;
      box-shadow: none;
    }
    &:hover {
      border: 1px solid transparent !important;
      box-shadow: none;
    }
  }
  @include media.d {
    div input {
      border: 1px solid transparent;
      min-width: auto;
      background: color.$grayscale100;
    }
  }
  @include media.dhd {
    div input {
      border: 1px solid transparent;
      min-width: 360px;
      background: color.$grayscale100;
    }
  }
}

.participantsList {
  overflow-y: scroll;
  scrollbar-width: none;
  list-style: none;
  padding: 0;
  margin: 0;

  & > li {
    border-top: 1px solid color.$grayscale100;

    &:first-of-type {
      border-top-color: transparent;
    }
  }

  & > li:last-child {
    border-bottom: 1px solid color.$grayscale100;
  }

  & > li:hover {
    border-top: 1px solid transparent;
  }

  & > li:hover + li {
    border-top: 1px solid transparent;
  }

  & > li:last-child:hover {
    border-bottom: 1px solid transparent;
  }
}

.participantsList::-webkit-scrollbar {
  width: 24px;
}

// .participantsList::-webkit-scrollbar-thumb {
//   border: 8px solid rgba(0, 0, 0, 0);
//   background-clip: padding-box;
//   border-radius: 12px;
//   background-color: color.$grayscale200;
// }

// .preventClickArea {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100vw;
//   height: 100vh;
//   background-color: black;
//   opacity: 0;
//   z-index: 9999;
// }
