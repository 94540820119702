@use "../../../styles/color";
@use "../../../styles/text";
@use "../../../styles/spacing";

.user_picture {
  margin-right: 8px;
}

.userName {
  flex-grow: 1;
  @include text.ellipsisOneRow();
  display: block; // fix for display: -webkit-box from text.ellipsisOneRow()
}

.userNameWrapper {
  display: flex;
  flex-wrap: nowrap;
  gap: spacing.$XXXXS;
  white-space: nowrap;
}

.userSummary {
  display: flex;
  align-items: center;
  height: 43px; // to keep height the same with/without company/username
  overflow: hidden; // for text-ellipsis in user
}

.userSummaryContainer {
  flex-grow: 1;
  min-width: 0;
}

.company {
  @include text.ellipsisOneRow();
}