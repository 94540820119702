.noYetMessagesWrap {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 14px;
  padding: 0 8px;
}

.text {
  white-space: pre-wrap;
}
