@use "src/styles/media";
@use "src/styles/text";

.dialogDescriptionWrap {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 24px;
  @include text.longWordBreak;

  > img {
    width: 48px;
    height: 48px;
    border-radius: 24px;
  }
}

.dialogDescriptionWrap + p {
  margin-bottom: 24px;
}

.modalWrap.modalWrap {
  @include media.tp {
    width: 583px;
  }
}
