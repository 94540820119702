@use '../../../styles/media';
@use '../../../styles/color';
@use '../../../styles/spacing';

$layoutPadding: 28px;

.stickyModal {
  position: fixed;
  bottom: 0;
  z-index: 99991;

  box-sizing: border-box;
  background: color.$white;
  box-shadow: 0 -1px 1px 0 rgba(242, 242, 242, 0.5), 0 4px 13px -5px rgba(189, 189, 189, 0.5);

  border-radius: 24px;
  padding: 24px;

  width: calc(100% - 2* #{spacing.$XXXXS});
  margin: 0 spacing.$XXXXS spacing.$XXXXS;

  @include media.tp {
    width: calc(100% - 2* #{$layoutPadding});
    margin: 0 $layoutPadding $layoutPadding;
  }
}
