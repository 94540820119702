@use "src/styles/color";
@use "src/styles/media";

.messageList {
  padding-top: 16px;
  padding-bottom: 28px;

  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  &::-webkit-scrollbar {
    width: 24px;
  }
  &::-webkit-scrollbar-thumb {
    border: 8px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 12px;
    background-color: color.$grayscale200;
  }

  &.isBlockScroll {
    overflow: hidden;
  }
}

.messageListInner {
  display: grid;
  gap: 8px;
  margin-top: auto;
}

.spinnerWrapper {
  display: flex;
  justify-content: center;
}
