@use "src/styles/color";
@use "src/styles/media";
@use "src/styles/spacing";
@use "src/styles/common.module";
@use "src/styles/text";

.wrap {
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  @extend .noSelect;
}

.inner {
  display: flex;
  background: color.$white;
  padding: spacing.$XXXS spacing.$XS;
  overflow: hidden;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  gap: 12px;

  &:hover {
    background: color.$grayscale100;
  }
  &:active {
    background: color.$grayscale300;
  }
}

.avatar {
  cursor: pointer;
}

.info {
  @include text.ellipsisAtRow(1);
  display: block;
  white-space: nowrap;
}
