@use "src/styles/color";
@use "src/styles/media";

.header_wrap {
  padding: 0 20px;

  @include media.tp {
    padding: unset;
  }
}

.wrap {
  position: absolute;
  top: 0;
  left: 0;
  background: color.$grayscale100;
  padding: 0;
  width: 100%;
  height: 100%;

  @include media.tp {
    padding: 0 28px;
  }
}

.inner {
  position: relative;
  background: color.$white;
  border-radius: 26px;
  height: calc(100% - 75px);

  @include media.tp {
    margin-bottom: 14px;
    height: calc(100% - 93px);
  }
}

.triangle {
  position: absolute;
  width: 80px;
  height: 18px;
  right: 46px;
  top: -13px;

  @include media.tp {
    right: 64px;
  }
}

.isHiddenOnDesktop {
  @include media.d {
    display: none;
  }
}
