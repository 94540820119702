@use "src/styles/color";
@use "../../../../variables" as var;

.unread {
  width: min-content;
  display: flex;
  align-items: center;
  justify-content: center;
  background: color.$violet600;
  border-radius: 90px;
  min-width: var.$unread-messages--size;
  height: var.$unread-messages--size;
  box-sizing: border-box;
  margin-left: auto;
}
