@use "src/styles/media";
@use "src/styles/common.module";

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  background: #f5f5f5;
}

.iconButton {
  width: auto !important; // [LINK_COMPONENT_STYLES]
  display: flex !important; // [LINK_COMPONENT_STYLES]
  align-items: center;
  justify-content: center;
}

.buttonsSection .createEventButton {
  display: none !important; // [LINK_COMPONENT_STYLES]

  @include media.tp {
    display: flex !important; // [LINK_COMPONENT_STYLES]
  }
}

.buttonsSection {
  @extend .noSelect;
  display: flex;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  justify-content: flex-end;
  gap: 24px;
}

.avatar {
  display: flex;
  width: 100%;
  align-items: center;
  flex-grow: 1;
  cursor: pointer;
  justify-content: flex-end;
  gap: 6px;
}

.avatarCp {
  gap: 6px;
  display: flex;
  position: relative;
  align-items: center;
  cursor: pointer;
}

.nav {
  display: none;
}

.menu {
  display: none;
}

.buttons {
  flex-grow: 1;
  justify-content: flex-end;
  display: flex;
}

.logo {
  display: flex;
  height: 27px;
  //max-width: 93.5px;
}

.buttonsGroup {
  display: none;
}

.wrap.exitIcon {
  display: none;
}

.navTablet {
  display: none;
}

.menuTablet {
  display: flex;
}

.buttonTablet {
  display: none;
}

@mixin bp768 {
  .header {
    height: 80px;
  }
  .buttonsGroup {
    display: flex;
    gap: 16px;
  }
  .wrap .exitIcon {
    display: block;
    cursor: pointer;
  }
  .logo {
    max-width: 118px;
    flex-grow: 1;
    height: 34px;
  }
  .menu {
    display: flex;
  }
}

@mixin bp1024 {
  .nav {
    display: flex;
    flex-grow: 2;
    gap: 48px;
    justify-content: center;
    align-items: center;
  }

  .logo {
    max-width: unset;
  }
  .buttons {
    width: 100%;
  }
  .menu {
    display: none;
  }
}

.arrowUp {
  transition: transform 0.3s linear;
}

.arrowDown {
  transform: rotate(180deg);
  transition: transform 0.3s linear;
}

.wrap .exitEvent_attendee {
  display: none;
  @include media.tp {
    display: flex;
    gap: 8px;
    margin-right: -12px;
  }
  @include media.d {
    align-items: center;
  }
  .exitEvent_text {
    display: none;
    @include media.ds {
      display: block;
    }
  }
}
.wrap .exitEvent_speaker_org {
  display: flex;
  gap: 8px;
  margin-right: -12px;
  align-items: center;
}

.padding_12_20 {
  padding: 12px 0;
  @include media.tp {
    padding: 20px 0;
  }
}
.headerLayout_speaker_org.padding_12_20 {
  padding: 20px 0;
}

.padding_12 {
  padding: 12px 0;
}

.headerLayout_attendee {
  @include media.tp {
    @include bp768;
  }
  @include media.tl {
    @include bp1024;
  }
}
.headerLayout_speaker_org {
  @include bp1024;
}

.headerLayout_speaker_org .logo svg {
  height: 34px !important;
  background-color: #1b63ff !important;
}
