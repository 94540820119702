@use "src/styles/spacing";

.container {
  overflow: hidden;
}

.content {
  * {
    line-height: inherit;
    word-break: break-word;
  }
}

// TODO kit
.moreLessButton.moreLessButton.moreLessButton {
  padding: 0;
  height: unset;
  margin-top: spacing.$XXXXS;
}
