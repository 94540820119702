@use 'src/styles/media';
.title {
  margin-bottom: 8px;
  @include media.tp {
    margin-bottom: 20px;
    text-align: center;
  }
}
.submit button{
  width: 100%;
}
.buttons{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  margin-top: 24px;
  margin-left: -12px;
}
.avatar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
  @include media.tp {
    gap: 12px;
  }
}