$brand: #ff9203;
$brand100: #ffb71b;
$brand200: #ffb316;
$brand300: #ffae10;
$brand400: #ffa70a;
$brand500: #ff9e06;
$brand600: #ff9203;
$brand700: #ff8300;
$brand800: #ff7200;
$brand900: #ff6100;
$brand1000: #ff5100;

$mint: #00af9e;
$mint100: #e6f7f5;
$mint200: #b3e7e2;
$mint300: #80d7cf;
$mint400: #4dc7bb;
$mint500: #1ab7a8;
$mint600: #00af9e;
$mint700: #009e8e;
$mint800: #008c7e;
$mint900: #007a6f;
$mint1000: #00695f;
$mintT1: rgba(0, 175, 158, 0.1);
$mintT2: rgba(0, 175, 158, 0.25);
$mintT3: rgba(0, 175, 158, 0.5);
$mintT4: rgba(0, 175, 158, 0.75);

$yellow: #ffb71b;
$yellow100: #fff8e8;
$yellow200: #ffe9bb;
$yellow300: #ffdb8d;
$yellow400: #ffcd5f;
$yellow500: #ffbe32;
$yellow600: #ffb71b;
$yellow700: #e6a518;
$yellow800: #cc9216;
$yellow900: #b38013;
$yellow1000: #996e10;
$yellowT1: rgba(255, 183, 27, 0.1);
$yellowT2: rgba(255, 183, 27, 0.25);
$yellowT3: rgba(255, 183, 27, 0.5);
$yellowT4: rgba(255, 183, 27, 0.75);

$tangerine: #ff8300;
$tangerine100: #fff3e6;
$tangerine200: #ffdab3;
$tangerine300: #ffc180;
$tangerine400: #ffa84d;
$tangerine500: #ff8f1a;
$tangerine600: #ff8300;
$tangerine700: #e67600;
$tangerine800: #cc6900;
$tangerine900: #b35c00;
$tangerine1000: #994f00;
$tangerineT1: rgba(255, 131, 0, 0.1);
$tangerineT2: rgba(255, 131, 0, 0.25);
$tangerineT3: rgba(255, 131, 0, 0.5);
$tangerineT4: rgba(255, 131, 0, 0.75);

$red: #cf3642;
$red100: #faebec;
$red200: #f1c3c6;
$red300: #e79ba1;
$red400: #dd727b;
$red500: #d44a55;
$red600: #cf3642;
$red700: #ba313b;
$red800: #a62b35;
$red900: #91262e;
$red1000: #7c2028;
$redT1: rgba(207, 54, 66, 0.1);
$redT2: rgba(207, 54, 66, 0.25);
$redT3: rgba(207, 54, 66, 0.5);
$redT4: rgba(207, 54, 66, 0.75);

$violet: #94368d;
$violet100: #f4ebf4;
$violet200: #dfc3dd;
$violet300: #ca9bc6;
$violet400: #b472af;
$violet500: #9f4a98;
$violet600: #94368d;
$violet700: #85317f;
$violet800: #762b71;
$violet900: #682663;
$violet1000: #592055;
$violetT1: rgba(148, 54, 141, 0.1);
$violetT2: rgba(148, 54, 141, 0.25);
$violetT3: rgba(148, 54, 141, 0.5);
$violetT4: rgba(148, 54, 141, 0.75);

$blue: #1b63ff;
$blue100: #e5edff;
$blue200: #b3cafe;
$blue300: #6a99fc;
$blue400: #3e7afc;
$blue500: #125dfc;
$blue600: #1b63ff;
$blue700: #1053df;
$blue800: #1049c2;
$blue900: #0f3fa8;
$blue1000: #0e368c;
$blueT1: rgba(27, 99, 255, 0.1);
$blueT2: rgba(27, 99, 255, 0.25);
$blueT3: rgba(27, 99, 255, 0.5);
$blueT4: rgba(27, 99, 255, 0.75);

$grayscale100: #f5f5f5;
$grayscale200: #dbdbdb;
$grayscale300: #c2c2c2;
$grayscale400: #a8a8a8;
$grayscale500: #8f8f8f;
$grayscale600: #757575;
$grayscale700: #5c5c5c;
$grayscale800: #424242;
$grayscale900: #292929;
$grayscale1000: #0f0f0f;

$black: #000;
$white: #fff;
