@use 'src/styles/color';
@use 'src/styles/media';

.wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  right: 4px;
  gap: 8px;
  position: absolute;
  z-index: 20;
  top: 48px;
  background: #FFFFFF;
  box-shadow: 0 0 0.5px rgba(0, 0, 0, 0.35), 0 5px 15px rgba(0, 0, 0, 0.15);
  border-radius: 12px;

  &_mobile {
    top: 32px;
  }

  @include media.d {
    right: 0px;
    max-width: 170px;
    left: unset;
  }
}
.close {
  display: none;
}
.divider {
  border: none;
  margin: 0;
  border-top: 1px solid color.$grayscale100;
  width: 100%;
}
.button {
  width: 100% !important; // [UI-Kit Style Priority]
  height: 20px !important; // [UI-Kit Style Priority]
  padding: 0 !important; // [UI-Kit Style Priority]
  justify-content: flex-start !important; // [UI-Kit Style Priority]
}

.link a {
  display: block;
}