@use "src/styles/color";
@use "src/styles/spacing";

.root {
  border-top: 1px solid color.$grayscale200;
  padding: spacing.$XXS spacing.$XS;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.inputWapper {
  width: 100%;
}

.textarea {
  resize: none;
  line-height: 1.5em;
  font-size: 16px;
  font-weight: 400;
  font-family: inherit;
  outline: none; // should show correct focus in the future for the ARIA
  border: none; // it also hides incorrect focus
  &::placeholder {
    color: color.$grayscale400;
    opacity: 1; /* Firefox */
  }
  &:hover::placeholder {
    color: color.$grayscale600;
  }
  &:focus::placeholder {
    color: color.$grayscale400;
  }
}

.submitBtn {
  border: none;
  padding-right: 0;
  padding-left: 8px;
  background-color: transparent;
  pointer-events: none;
  opacity: 0;

  &.isVisible {
    opacity: 1;
    pointer-events: initial;
    cursor: pointer;
  }
}

.restrictedToMessageWrap {
  display: flex;
  align-items: center;
  padding: 28px 24px;
  border-top: 1px solid color.$grayscale200;
  gap: 16px;
}
