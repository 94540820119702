@use "src/styles/color";
@use "src/styles/media";
@use "../../../../variables" as var;

.wrap {
  position: relative;
  overflow: hidden;
  background: var.$list-item--background--normal;
  border-color: var.$list-separator--color;
  border-width: var.$list-separator--width 0;
  border-style: solid;
  z-index: 1;
  padding: 0 var.$list-item-wrapper--padding-x;

  &:first-of-type {
    border-top-width: 0;
  }

  &:not(:first-of-type) {
    margin-top: -(var.$list-separator--width);
  }

  &:hover {
    z-index: 2;
    border-color: var.$list-item--background--normal;
  }
}

.inner {
  display: flex;
  padding: var.$list-item--padding--m (var.$list-item--padding--m - var.$list-item-wrapper--padding-x);
  overflow: hidden;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  border-radius: 26px;

  @at-root .wrap:hover & {
    background: var.$list-item--background--hover;
  }

  @at-root .wrap:active & {
    background: var.$list-item--background--active;
  }

  @include media.tp {
    padding: 16px;
  }
  @include media.d {
    padding: 16px 12px;
  }
  @include media.dhd {
    padding: 16px;
  }
}


.avatar,
.avatar img {
  cursor: pointer;

  @include media.tp {
    width: 48px !important; // [UI-Kit Style Priority]
    height: 48px !important; // [UI-Kit Style Priority]
  }
  @include media.d {
    width: 40px !important; // [UI-Kit Style Priority]
    height: 40px !important; // [UI-Kit Style Priority]
  }
  @include media.dhd {
    width: 48px !important; // [UI-Kit Style Priority]
    height: 48px !important; // [UI-Kit Style Priority]
  }
}

.info {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  justify-content: space-between;
  gap: 4px;
  margin-left: 8px;
  overflow: hidden;
}

.header {
  display: flex;
  align-items: center;
}


.footer {
  display: grid;
  grid-template-columns: auto var.$unread-messages--size;
  overflow: hidden;
  gap: 4px;
}


.name_style {
  display: flex;
  overflow: hidden;
  > p {
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
