@use "src/styles/color";
@use "src/styles/media";
@use "src/styles/text";
@use "src/styles/spacing";

.wrap {
  display: flex;
  justify-content: center;
  width: 100% !important; // [UI-Kit Style Priority][Link]
  align-self: center;
}

.head {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: spacing.$XXXS;

  text-align: center;

  .link {
    width: 100%;
    cursor: pointer;
  }

  .avatar {
    box-sizing: border-box;
    margin: 0;
    width: 108px;
    height: 108px;
    border-radius: 50%;
    padding: 4px;
    border: 1px solid color.$grayscale200;
    object-fit: cover;
  }

  .text {
    word-break: break-all;
    @include text.ellipsisAtRow(1);
  }

}


