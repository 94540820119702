@use 'src/styles/media';
@use 'src/styles/spacing';
@use 'src/styles/grid';

@mixin wrapper {
  padding-top: spacing.$XS;

  // Prevent Honeycomb paddings
  padding-left: 0 !important;
  padding-right: 0 !important;

  $columns: (
    mp: 4,
    lp: 6,
    tl: 7
  );
  @include grid.wrap($columns);

  @include media.tp {
    padding-top: spacing.$M;
  }
  @include media.ds {
    padding-top: spacing.$L;
  }
}

@mixin wrapper-inner {
  grid-area: auto / 1 / auto / 5;

  @include media.d {
    grid-area: auto / 2 / auto / 7;
  }
}