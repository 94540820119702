@use "src/styles/color";
@use "src/styles/media";
@use "src/styles/common.module";
@use "../../common" as account;
@use "src/styles/spacing";
.wrap {
  @include account.wrapper;
}

.wrap_inner {
  @include account.wrapper-inner;
}

.title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  //@include media.tp{
  //  margin-bottom: 24px;
  //}
  @include media.d {
    margin-bottom: 24px;
  }
}
.hr_top {
  display: none;
  @include media.d {
    display: inline-block;
    border: none;
    margin: 0;
    border-top: 1px solid color.$grayscale100;
    width: 100%;
  }
}
.hr_bottom {
  border: none;
  border-top: 1px solid color.$grayscale100;
  width: 100%;
  margin: 16px 0;
}

.title {
  display: none;
  @include media.d {
    display: flex;
    justify-content: space-between;
  }
}
.group svg {
  width: 12px;
}
.group {
  flex-direction: column;
  display: flex;
  gap: 12px;
  margin-top: 12px;
  margin-bottom: 24px;
  padding-left: 20px;
}
.description {
  margin-top: 16px;
  margin-bottom: 16px;
  @include media.tp {
    margin-top: 24px;
    margin-bottom: 28px;
  }
  @include media.d {
    margin-top: unset;
  }
}
.profile_types {
  @extend .noSelect;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
  margin-bottom: 16px;
}
@media screen and (min-width: 540px) {
  .profile_types {
    flex-direction: row;
    flex-wrap: nowrap;
  }
}
.visibility {
  display: block;
}
.display_none {
  display: none;
}
.form_width {
  width: 100%;
}
.submit_button {
  width: 100%;
  margin-top: 24px;
  @include media.tp {
    width: 154px;
  }
}
.checkbox_wrapper {
  display: flex;
  flex-direction: column;
  gap: spacing.$XS;
  @include media.tp {
    gap: spacing.$XXXS;
  }
}
