@use 'src/styles/media';

.inputs {
  margin: {
    top: 24px;
    bottom: 16px;
  };
  display: flex;
  flex-direction: column;
  gap: 16px;
  input {
    min-width: unset;
  }
}

.list {
  margin: 0;
  padding-left: 24px;
  margin-top: 4px;
}

.submit button{
  width: 100%;
  height: 48px;
  @include media.tp {
    height: 56px;
  }
}


.title {
  margin-bottom: 8px;
  @include media.tp {
    margin-bottom: 20px;
  }
}

.info {
  margin-bottom: 12px;
  @include media.tp{
    margin-bottom: 16px;
  }
}
.attention {
  margin-bottom: 24px;
}
.delete_modal {
  @include media.tp {
    width: 388px;
  }
}