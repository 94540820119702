 .wrap input[type="file"] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.wrap{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
}